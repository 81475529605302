import AccessibilityDeclarationTemplate from "@templates/accessibilityDeclarationTemplate"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

function AccessibilityDeclarationKindergarden() {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Deklaracja dostępności - ZSP Nidek" />
      <AccessibilityDeclarationTemplate />
    </Layout>
  )
}

export default AccessibilityDeclarationKindergarden
